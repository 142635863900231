import { string, func } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

import { DEFAULT_COLORS } from 'constants/colors';

const AriesIcon = ({
    color,
    fill,
    opacity1,
    opacity2,
    opacity3,
    height,
    onClick,
    viewBox,
    width,
    ...rest
}) => {
    return (
        <SvgIcon
            data-testid="aries-icon"
            width={width}
            height={height}
            onClick={onClick}
            viewBox={viewBox}
            {...rest}
        >
            <path
                fill={fill}
                d="M15.9,29.7c-0.7,0-1.2-0.6-1.2-1.2V3.6c0-0.7,0.5-1.2,1.2-1.2c0.7,0,1.2,0.6,1.2,1.2v24.9
                C17.1,29.1,16.5,29.7,15.9,29.7z"
            />
            <path
                fill={fill}
                style={{ opacity: opacity1 }}
                d="M20.8,25c-0.7,0-1.2-0.6-1.2-1.2V8.3c0-0.7,0.5-1.2,1.2-1.2C21.4,7,22,7.6,22,8.3v15.4
                C22,24.4,21.4,25,20.8,25z"
            />
            <path
                fill={fill}
                style={{ opacity: opacity2 }}
                d="M25.6,20.4c-0.7,0-1.2-0.6-1.2-1.2v-6.2c0-0.7,0.5-1.2,1.2-1.2c0.7,0,1.2,0.6,1.2,1.2v6.2
                C26.9,19.8,26.3,20.4,25.6,20.4z"
            />
            <path
                fill={fill}
                style={{ opacity: opacity3 }}
                d="M30.5,18.9c-0.7,0-1.2-0.6-1.2-1.2v-3.2c0-0.7,0.5-1.2,1.2-1.2c0.7,0,1.2,0.6,1.2,1.2v3.2
                C31.7,18.3,31.2,18.9,30.5,18.9z"
            />
            <path
                fill={fill}
                style={{ opacity: opacity1 }}
                d="M11,25c-0.7,0-1.2-0.6-1.2-1.2V8.3C9.8,7.6,10.3,7,11,7c0.7,0,1.2,0.6,1.2,1.2v15.4C12.2,24.4,11.7,25,11,25z"
            />
            <path
                fill={fill}
                style={{ opacity: opacity2 }}
                d="M6.1,20.4c-0.7,0-1.2-0.6-1.2-1.2v-6.2c0-0.7,0.5-1.2,1.2-1.2s1.2,0.6,1.2,1.2v6.2C7.3,19.8,6.8,20.4,6.1,20.4
                z"
            />
            <path
                fill={fill}
                style={{ opacity: opacity3 }}
                d="M1.2,18.9c-0.7,0-1.2-0.6-1.2-1.2v-3.2c0-0.7,0.5-1.2,1.2-1.2s1.2,0.6,1.2,1.2v3.2C2.4,18.3,1.9,18.9,1.2,18.9
                z"
            />
            <g>
                <path
                    fill={color}
                    d="M25.2,2.5L25.2,2.5c-3.5,0-6.4,2.9-6.4,6.4l0,0c0,3.5,2.9,6.4,6.4,6.4l0,0c3.5,0,6.4-2.9,6.4-6.4l0,0
                    C31.6,5.3,28.8,2.5,25.2,2.5z"
                />
            </g>
        </SvgIcon>
    );
};

AriesIcon.propTypes = {
    color: string,
    fill: string,
    opacity1: string,
    opacity2: string,
    opacity3: string,
    height: string,
    onClick: func,
    viewBox: string,
    width: string,
};

AriesIcon.defaultProps = {
    color: DEFAULT_COLORS.BLUE,
    fill: DEFAULT_COLORS.BLACK,
    opacity1: '0.8',
    opacity2: '0.5',
    opacity3: '0.3',
    height: '32',
    onClick: () => {},
    viewBox: '0 0 32 32',
    width: '32',
};

export default AriesIcon;
