import _get from 'lodash/get';
import { call, put } from 'redux-saga/effects';

import {
    createConfigurationAPI,
    deleteConfigurationAPI,
    fetchConfigurationByIdAPI,
    fetchConfigurations,
    fetchConfigurationTypes,
    fetchEHSProductsAPI,
    updateConfigurationAPI,
    fetchSkusByConfigurationIdAPI,
} from 'modules/apis';
import { actions as uiActions } from 'modules/ui';
import * as adminActions from '../actions';

/**
 * Normalize the data for a given configuration for the 'put' API call.
 *
 * @method _normalizeUpdateConfigData
 * @param  {Object}                   config The configuration to normalize
 * @return {Object}                          The normalized data
 */
const _normalizeUpdateConfigData = config => ({
    allowUpdate: _get(config, 'allowUpdate', false),
    assignLearnToAlternate: _get(config, 'assignLearnToAlternate'),
    configurationKey: _get(config, 'configurationKey', ''),
    configurationName: _get(config, 'configurationName', ''),
    description: _get(config, 'description', ''),
    skus: _get(config, 'skus', []),
});

/**
 * Fetch the configuration by the `configId`.
 *
 * @method fetchConfigSaga
 * @type   {Generator}
 * @param  {Object}         action The redux action
 * @return {Object}
 */
export function* fetchConfigSaga(action) {
    try {
        // Extract the `configurationId` from the action.
        const { payload: configId } = action;
        // Make the API call to fetch the configuration with the given `configId`.
        const resp = yield call(fetchConfigurationByIdAPI, configId);
        // Extract the configuration from the valid response.
        const config = _get(resp, 'data.configuration', {});
        // Dispatch the success action.
        yield put(adminActions.fetchConfig.success(config));
        // Return the config.
        return config;
    } catch (error) {
        // Dispatch the error action.
        yield put(adminActions.fetchConfig.error(error));
        // Return the error.
        return error;
    }
}

/**
 * Update a single configuration
 *
 * @method updateConfigSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* updateConfigSaga(action) {
    try {
        // Extract the `configurationId` from the action.
        const configId = _get(action, 'payload.configurationId');
        // Normalize the configuration data for the API.
        const updatedConfig = _normalizeUpdateConfigData(action.payload);
        // Make the API call to update the configuration
        const resp = yield call(updateConfigurationAPI, configId, updatedConfig);
        // Extract the configuration from the valid response.
        const config = _get(resp, 'data.configuration', {});
        // Dispatch the success action.
        yield put(adminActions.updateConfig.success(config));
        // Return the config.
        return config;
    } catch (error) {
        // Dispatch the error action.
        yield put(adminActions.updateConfig.error(error));
        // Return the error.
        return error;
    }
}

/**
 * Create a single configuration
 *
 * @method createConfigSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* createConfigSaga(action) {
    try {
        // Extract the `partnerId` from the action.
        const { partnerId, ...data } = _get(action, 'payload');
        // Update the config data.
        const configData = {
            ...data,
            // Need to send empty list of SKUs as skus field is mandatory in the API
            skus: [],
        };
        // Make the API call to create the configuration
        const resp = yield call(createConfigurationAPI, configData, partnerId);
        // Extract the newly created configuration from the valid response.
        const config = _get(resp, 'data.configuration', {});
        // Dispatch the success action.
        yield put(adminActions.createConfig.success(config));
        // Return the config.
        return config;
    } catch (error) {
        // Dispatch the error action.
        yield put(adminActions.createConfig.error(error));
        // Return the error.
        return error;
    }
}

/**
 * Delete configuration saga
 *
 * @method deleteConfigurationSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* deleteConfigurationSaga(action) {
    try {
        yield put(uiActions.isLoading(true, 'Deleting Configurations'));
        const { payload: configurationId } = action;
        const response = yield call(deleteConfigurationAPI, configurationId);
        yield put(adminActions.deleteConfiguration.success(response));
        return response;
    } catch (error) {
        yield put(adminActions.deleteConfiguration.error(error));
        return error;
    } finally {
        yield put(uiActions.isLoading(false));
    }
}

/**
 * Fetch list of configurations
 *
 * @method fetchConfigurationsSaga
 * @type   {Generator}
 * @param  {Object}          action The redux action
 * @return {Object}
 */
export function* fetchConfigurationsSaga(action) {
    const { payload: params } = action;
    const { notLoading = false } = params;
    delete params.notLoading;

    try {
        if (!notLoading) {
            yield put(uiActions.isLoading(true, 'Fetching Configurations'));
        }

        const response = yield call(fetchConfigurations, params);
        const configurations = _get(response, 'data.configurations', []);
        const totalRecords = _get(response, 'data.totalRecords', 0);

        yield put(
            adminActions.fetchConfigurations.success({
                configurations,
                totalRecords: Number(totalRecords),
            })
        );
        return {
            configurations,
            totalRecords: Number(totalRecords),
        };
    } catch (error) {
        adminActions.fetchConfigurations.error(error);
        return error;
    } finally {
        if (!notLoading) {
            yield put(uiActions.isLoading(false));
        }
    }
}

/**
 * Fetch configuration types
 *
 * @method fetchConfigurationTypesSaga
 * @type   {Generator}
 * @return {Object}
 */
export function* fetchConfigurationTypesSaga() {
    try {
        const response = yield call(fetchConfigurationTypes);
        const data = _get(response, 'data', {});
        yield put(adminActions.fetchConfigurationTypes.success(data));
        return data;
    } catch (error) {
        adminActions.fetchConfigurationTypes.error(error);
        return error;
    }
}

/**
 * Fetch the EHSProducts by the `userId`.
 *
 * @method fetchEHSProductSaga
 * @type   {Generator}
 * @param  {Object}         action The redux action
 * @return {Object}
 */
export function* fetchEHSProductSaga(action) {
    try {
        const { payload: userId } = action;
        const resp = yield call(fetchEHSProductsAPI, userId);
        const product = _get(resp, 'data', {});
        yield put(adminActions.fetchEHSProducts.success(product));
        return product;
    } catch (error) {
        yield put(adminActions.fetchEHSProducts.error(error));
        return error;
    }
}

/**
 * Fetch skus by configuration id.
 *
 * @method fetchSkusByConfigurationIdSaga
 * @type   {Generator}
 * @param  {Object}         action The redux action
 * @return {Object}
 */
export function* fetchSkusByConfigurationIdSaga(action) {
    try {
        const { payload } = action;
        const resp = yield call(fetchSkusByConfigurationIdAPI, payload);
        const companySKUs = _get(resp, 'data.companySKUs', {});
        yield put(adminActions.fetchSkusByConfigurationId.success(companySKUs));
        return companySKUs;
    } catch (error) {
        yield put(adminActions.fetchSkusByConfigurationId.error(error));
        return error;
    }
}
