import { func } from 'prop-types';
import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';

import { MODALS } from 'constants/modals';
import { actions as uiActions } from 'modules/ui';

const AddConfigModal = lazy(() => import('./AddConfigModal'));
const AddSkusModal = lazy(() => import('./AddSkusModal'));
const AddSkusModalPackaging = lazy(() => import('./AddSkusModalPackaging'));
const AssignConfigModal = lazy(() => import('./AssignConfigModal'));
const AdminFilterModal = lazy(() => import('./AdminFilterModal'));
const ConfirmHandbookStateModal = lazy(() => import('./ConfirmHandbookStateModal'));
const ConfirmModal = lazy(() => import('./ConfirmModal'));
const CommentsModal = lazy(() => import('./CommentsModal'));
const DependentSkuWarningModal = lazy(() => import('./DependentSkuWarningModal'));
const DiffCheckerModal = lazy(() => import('./DiffCheckerModal'));
const MultiDiffCheckerModal = lazy(() => import('./MultiDiffCheckerModal'));
const FilterModal = lazy(() => import('./FilterModal'));
const HandbooksEliteModal = lazy(() => import('./HandbooksEliteModal'));
const LinkCustomPolicy = lazy(() => import('./LinkCustomPolicy'));
const LivingHandbookModal = lazy(() => import('./LivingHandbookModal'));
const LoginModal = lazy(() => import('./LoginModal'));
const MultiStateHandbookModal = lazy(() => import('./MultiStateHandbookModal'));
const NewsletterPreviewModal = lazy(() => import('./NewsletterPreviewModal'));
const PolicyPreviewModal = lazy(() => import('./PolicyPreviewModal'));
const RolePartnerModal = lazy(() => import('./RolePartnerModal'));
const SalaryCompareModal = lazy(() => import('./SalaryCompareModal'));
const TermsAndConditionsModal = lazy(() => import('./TermsAndConditionsModal'));
const UpdateBannerModal = lazy(() => import('./UpdateBannerModal'));
const MoveCompanyModal = lazy(() => import('./MoveCompanyModal'));
const WelcomeEmailEditorModal = lazy(() => import('./WelcomeEmailEditorModal'));
const SignDocModal = lazy(() => import('./SignDocModal'));
const SendForEsigModal = lazy(() => import('./SendForEsigModal'));
const SessionExpiryAlert = lazy(() => import('./SessionExpiryAlert'));
const UploadDocModal = lazy(() => import('./UploadDocModal'));
const UnsavedChanges = lazy(() => import('./UnsavedChanges'));
const EsignRemindModal = lazy(() => import('./EsignRemindModal'));
const ActivityStreamDetailModal = lazy(() => import('./ActivityStreamDetailModal'));
const CreateBannerModal = lazy(() => import('./CreateBannerModal'));
const AddNicknameModal = lazy(() => import('./AddNicknameModal'));
const NewConfirmModal = lazy(() => import('./NewConfirmModal'));
const AdminSelectListModal = lazy(() => import('./AdminSelectListModal'));
const AdminSendTestEmailModal = lazy(() => import('./AdminSendTestEmailModal'));
const SendTestWelcomeEmailModal = lazy(() => import('./SendTestWelcomeEmailModal'));
const WelcomeEmailPreviewModal = lazy(() => import('./WelcomeEmailPreviewModal'));
const DuplicateSkusModal = lazy(() => import('./DuplicateSkusModal'));
const CompanyDetailsUpdateReminderModal = lazy(() => import('./CompanyDetailsUpdateReminderModal'));
const CalendarEventModal = lazy(() => import('./CalendarEventModal'));
const CalculationValuesModal = lazy(() => import('./CalculationValuesModal'));
const RecordableIncidentWizardModal = lazy(() => import('./RecordableIncidentWizardModal'));
const DownloadOshaReportModal = lazy(() => import('./DownloadOshaReportModal'));
const AccountUpdatePasswordModal = lazy(() => import('./AccountUpdatePasswordModal'));
const DowntimeWarningModal = lazy(() => import('./DowntimeWarningModal'));
const WelcomInsightsModal = lazy(() => import('./WelcomeInsightsModal'));
const ResourcesModal = lazy(() => import('./ResourcesModal'));
const ResourcesEditModal = lazy(() => import('./ResourcesEditModal'));
const NominateForVPAModal = lazy(() => import('./NominateForVPAModal'));
const CaseAnswerOverwriteModal = lazy(() => import('./CaseAnswerOverwriteModal'));
const ViewPreppedAnswersModal = lazy(() => import('./ViewPreppedAnswersModal'));
const OpenIdErrorModal = lazy(() => import('./OpenIdErrorModal'));
const EditorURLModal = lazy(() => import('./EditorURLModal'));
const ViewAllProductsModal = lazy(() => import('./ViewAllProductsModal'));
const RecommendationDetailModal = lazy(() => import('./RecommendationDetailModal'));
const FeedbackModal = lazy(() => import('./FeedbackModal'));
const DeleteConfigurationModal = lazy(() => import('./DeleteConfigurationModal'));
const ImportProcessingModal = lazy(() => import('./ImportProcessingModal'));
const MessageReviewModal = lazy(() => import('./MessageReviewModal'));
const AdvancedSearchModal = lazy(() => import('./AdvancedSearchModal'));
const ImagePreviewModal = lazy(() => import('./ImagePreviewModal'));

class Modals extends Component {
    static propTypes = {
        addModal: func.isRequired,
    };

    componentDidMount = () => {
        const { addModal } = this.props;
        // Define an empty `modalsList`.
        const modalsList = [];
        // Dynamically add modals to the `modalsList`.
        for (const modalId in MODALS) {
            if (Object.prototype.hasOwnProperty.call(MODALS, modalId)) {
                modalsList.push(MODALS[modalId]);
            }
        }
        // Update the application's state with the updated list of modals.
        addModal(modalsList);
    };

    render() {
        return (
            <Suspense fallback={<div />}>
                <AccountUpdatePasswordModal />
                <AddSkusModal />
                <AddSkusModalPackaging />
                <AddConfigModal />
                <AdminFilterModal />
                <AssignConfigModal />
                <ConfirmHandbookStateModal />
                <ConfirmModal />
                <CommentsModal />
                <DependentSkuWarningModal />
                <DiffCheckerModal />
                <MultiDiffCheckerModal />
                <FilterModal />
                <HandbooksEliteModal />
                <LinkCustomPolicy />
                <LivingHandbookModal />
                <LoginModal />
                <MultiStateHandbookModal />
                <NewsletterPreviewModal />
                <PolicyPreviewModal />
                <RolePartnerModal />
                <SalaryCompareModal />
                <TermsAndConditionsModal />
                <UpdateBannerModal />
                <MoveCompanyModal />
                <WelcomeEmailEditorModal />
                <SignDocModal />
                <SendForEsigModal />
                <SessionExpiryAlert />
                <UploadDocModal />
                <UnsavedChanges />
                <EsignRemindModal />
                <ActivityStreamDetailModal />
                <CreateBannerModal />
                <AddNicknameModal />
                <NewConfirmModal />
                <AdminSelectListModal />
                <AdminSendTestEmailModal />
                <SendTestWelcomeEmailModal />
                <WelcomeEmailPreviewModal />
                <DuplicateSkusModal />
                <CompanyDetailsUpdateReminderModal />
                <CalendarEventModal />
                <CalculationValuesModal />
                <RecordableIncidentWizardModal />
                <DownloadOshaReportModal />
                <DowntimeWarningModal />
                <WelcomInsightsModal />
                <ResourcesModal />
                <ResourcesEditModal />
                <NominateForVPAModal />
                <CaseAnswerOverwriteModal />
                <ViewPreppedAnswersModal />
                <OpenIdErrorModal />
                <EditorURLModal />
                <ViewAllProductsModal />
                <RecommendationDetailModal />
                <FeedbackModal />
                <DeleteConfigurationModal />
                <ImportProcessingModal />
                <MessageReviewModal />
                <AdvancedSearchModal />
                <ImagePreviewModal />
            </Suspense>
        );
    }
}

const mapDispatchToProps = {
    addModal: uiActions.addModal,
};

export { Modals as ModalsListUnwrapped };
export default connect(null, mapDispatchToProps)(Modals);
