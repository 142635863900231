import { object } from 'prop-types';
import React from 'react';
import { withTheme } from 'styled-components';

import Tooltip from 'ravenjs/lib/Tooltip';
import { getThemeProps } from 'ravenjs/utils/theme';

import { AriesIcon } from 'components/Icons';
import { TRY_ARIES } from 'constants/ai';
import { DEFAULT_COLORS } from 'constants/colors';

function AINav({ theme }) {
    const renderIcon = () => {
        return (
            <AriesIcon
                fill={getThemeProps('NavItem.styles.color', DEFAULT_COLORS.BLACK, {
                    theme,
                })}
                width="20"
                height="20"
                cursor="pointer"
                onClick={() => {
                    window.location.href = '/aries';
                }}
            />
        );
    };

    return (
        <Tooltip
            content={TRY_ARIES}
            padding="4px 16px"
            margin={0}
            arrowSize={5}
            placement="top"
            borderRadius="5px"
        >
            {renderIcon()}
        </Tooltip>
    );
}

AINav.propTypes = {
    theme: object.isRequired,
};

export { AINav as AINavUnwrapped };
export default withTheme(AINav);
