import { takeLatest } from 'redux-saga/effects';

import * as acaActions from '../action';

import {
    createAnswerSaga,
    deleteAnswerSaga,
    fetchAnswerListSaga,
    fetchQuestionnaireInfoSaga,
    fetchQuestionnaireStatusSaga,
    fetchQuestionsSaga,
    setQuestionnaireStatusSaga,
    updateAnswerSaga,
} from './acaReporting';

/**
 * The root `acaReportingSaga` saga.
 * We're triggering sagas based on their respective events.
 *
 * @method authSaga
 * @type   {Generator}
 */
function* acaReportingSaga() {
    yield takeLatest(acaActions.fetchQuestionnaireInfo.TRIGGER, fetchQuestionnaireInfoSaga);
    yield takeLatest(acaActions.fetchQuestionnaireStatus.TRIGGER, fetchQuestionnaireStatusSaga);
    yield takeLatest(acaActions.fetchQuestions.TRIGGER, fetchQuestionsSaga);
    yield takeLatest(acaActions.setQuestionnaireStatus.TRIGGER, setQuestionnaireStatusSaga);
    yield takeLatest(acaActions.fetchAnswerList.TRIGGER, fetchAnswerListSaga);
    yield takeLatest(acaActions.createAnswer.TRIGGER, createAnswerSaga);
    yield takeLatest(acaActions.updateAnswer.TRIGGER, updateAnswerSaga);
    yield takeLatest(acaActions.deleteAnswer.TRIGGER, deleteAnswerSaga);
}

// Default export is the module's root saga.
export default acaReportingSaga;

// Named exports are all the internal sagas that can be used
// for testing as well as imports in other modules
export {
    fetchQuestionnaireInfoSaga,
    fetchQuestionnaireStatusSaga,
    fetchQuestionsSaga,
    setQuestionnaireStatusSaga,
    fetchAnswerListSaga,
    createAnswerSaga,
    updateAnswerSaga,
    deleteAnswerSaga,
};
