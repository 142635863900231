import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import Row from 'ravenjs/lib/Row';
import Typography from 'ravenjs/lib/Typography';
import { getThemeProps } from 'ravenjs/utils/theme';
import InfoIcon from 'ravenjs/lib/InfoIcon';
import Tooltip from 'ravenjs/lib/Tooltip';

import { DEFAULT_COLORS } from 'constants/colors';

const FormGroupTitleStyled = styled.label`
    align-items: baseline;
    color: #15191d;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    justify-content: space-between;
    margin: 0;
    padding: 0 5px 7.5px 5px;
    /**
     * Add all of the remaining styles from theme
     */
    ${getThemeProps('FormGroupTitle.styles')};
    ${({ styles }) => styles}
    svg {
        cursor: pointer;
    }
`;

function FormGroupTitle({
    id,
    hideRequired,
    required,
    title,
    helpText,
    uiOptions,
    showOptionalMark,
}) {
    const { icon = {}, ...tooltip } = helpText;
    const styles = _get(uiOptions, 'styles', {});

    return (
        title && (
            <FormGroupTitleStyled htmlFor={id} styles={styles}>
                {!hideRequired && required ? (
                    <Row alignItems="center" gutter={false} margin="0 5px 0 0" wrap="nowrap">
                        <span
                            style={{
                                marginRight: 5,
                                color: DEFAULT_COLORS.RED,
                                fontSize: '14px',
                                fontFamily: 'Roboto',
                                fontWeight: 500,
                                lineHeight: '19px',
                                wordWrap: 'break-word',
                            }}
                        >
                            {title}
                        </span>
                        {typeof helpText === 'object' && !_isEmpty(helpText) && (
                            <Tooltip {...tooltip}>
                                <InfoIcon {...icon} />
                            </Tooltip>
                        )}
                    </Row>
                ) : (
                    <Row alignItems="center" gutter={false} margin="0 5px 0 0" wrap="nowrap">
                        <span style={{ marginRight: 5 }}>{title}</span>
                        {typeof helpText === 'object' && !_isEmpty(helpText) && (
                            <Tooltip {...tooltip}>
                                <InfoIcon {...icon} />
                            </Tooltip>
                        )}
                        {showOptionalMark && (
                            <Typography
                                fontFamily="Roboto"
                                as="span"
                                color={DEFAULT_COLORS.BLACK}
                                gutterTop="0"
                                gutterBottom="0"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="19px"
                                wordWrap
                            >
                                (Optional)
                            </Typography>
                        )}
                    </Row>
                )}
            </FormGroupTitleStyled>
        )
    );
}

FormGroupTitle.propTypes = {
    helpText: PropTypes.object,
    id: PropTypes.string,
    hideRequired: PropTypes.bool,
    required: PropTypes.bool,
    title: PropTypes.string,
    uiOptions: PropTypes.object,
    showOptionalMark: PropTypes.bool,
};

FormGroupTitle.defaultProps = {
    helpText: {},
    id: null,
    hideRequired: false,
    required: false,
    title: null,
    uiOptions: {},
    showOptionalMark: false,
};

export default FormGroupTitle;
