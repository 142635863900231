import { string } from 'prop-types';
import React from 'react';

import SvgIcon from 'ravenjs/lib/SvgIcon';

const GridIcon = ({ height, viewBox, width }) => {
    return (
        <SvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM4 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM2 20a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM20 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM20 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM18 20a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM10 4a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM12 18a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
                fill="#000"
            />
        </SvgIcon>
    );
};

GridIcon.propTypes = {
    height: string,
    viewBox: string,
    width: string,
};

GridIcon.defaultProps = {
    width: '24',
    height: '24',
    viewBox: '0 0 24 24',
};

export default GridIcon;
