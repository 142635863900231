import ArrayFieldTemplate from './ArrayFieldTemplate';
import CompanyConfigField from './CompanyConfigField';
import DescriptionField from './DescriptionField';
import FileUploadField from './FileUploadField';
import FormGroup from './FormGroup';
import FormGroupContent from './FormGroupContent';
import FormGroupTitleField from './FormGroupTitle';
import HandbookSearchField from './HandbookSearchField';
import InputWithButtonField from './InputWithButtonField';
import InputWithButtonListField from './InputWithButtonListField';
import InputWithTooltipField from './InputWithTooltipField';
import LegislationComplyTopicsField from './LegislationComplyTopicsField';
import LegislationDateField from './LegislationDateField';
import LegislationLinkField from './LegislationLinkField';
import LegislationNotesField from './LegislationNotesField';
import LegislationPublishToField from './LegislationPublishToField';
import LegislationStatusField from './LegislationStatusField';
import LocationField from './LocationField';
import ObjectFieldTemplate from './ObjectFieldTemplate';
import TitleField from './TitleField';
import TransferField from './TransferField';
import UploadFieldDescription from './UploadFieldDescription';
import InputWithPasswordIconField from './InputWithPasswordIconField';
import InputCustomizableField from './InputCustomizableField';
import InputUsernameField from './InputUsernameField';
import InputCustomizableInlineField from './InputCustomizableInlineField';
import DisplayRequiredWithIconField from './DisplayRequiredWithIconField';
import FormDescriptionTextField from './FormDescriptionTextField';
import ChooseFileUploadField from './ChooseFileUploadField';

const fields = {
    ArrayFieldTemplate,
    CompanyConfigField,
    DescriptionField,
    FieldTemplate: FormGroup,
    FileUploadField,
    ChooseFileUploadField,
    FormGroupContent,
    FormGroupTitleField,
    FormDescriptionTextField,
    HandbookSearchField,
    InputWithButtonField,
    InputWithButtonListField,
    InputWithPasswordIconField,
    InputWithTooltipField,
    InputCustomizableField,
    InputCustomizableInlineField,
    InputUsernameField,
    LegislationComplyTopicsField,
    LegislationDateField,
    LegislationLinkField,
    LegislationNotesField,
    LegislationPublishToField,
    LegislationStatusField,
    LocationField,
    ObjectFieldTemplate,
    TitleField,
    TransferField,
    UploadFieldDescription,
    DisplayRequiredWithIconField,
};

export default fields;
