import PropTypes from 'prop-types';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import _get from 'lodash/get';
import _format from 'date-fns/format';
import _set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';

import ContentTooltipPreview from 'components/ContentTooltipPreview';
import { InfoIcon } from 'components/Icons';
import { DEFAULT_COLORS } from 'constants/colors';

import { get } from '../../utils';
import DisplayRequiredWithIconField from '../Form/fields/DisplayRequiredWithIconField';
import FormGroupTitle from '../Form/fields/FormGroupTitle';

export const StyledWrapper = styled.div`
    .react-datepicker__input-container input {
        border-radius: 2px;
        border-color: ${props => (props.required ? DEFAULT_COLORS.RED : DEFAULT_COLORS.GREY_16)};
    }

    .react-datepicker__input-container button {
        border-radius: 2px;
        border-color: ${props => (props.required ? DEFAULT_COLORS.RED : DEFAULT_COLORS.GREY_16)};
    }
`;

export const TitleStyled = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    label {
        padding-right: 0;
    }
    svg {
        position: relative;
        top: -5px;
        margin: 0;
    }
    height: ${({ titleHeight }) => `${titleHeight}`};
`;

function DatePicker(props) {
    const {
        onBlur,
        onChange,
        onSelect,
        placeholder,
        required,
        formContext,
        idSchema,
        uiSchema,
        selected,
        schema,
        ...rest
    } = props;

    const { taintedFields = [], pristine = true } = formContext;
    const uiOptions = get(uiSchema, 'ui:options', {});
    const titleHeight = get(uiOptions, 'titleHeight', '');
    const titleHelpText = get(schema, 'helpText', {});
    const hideRequired = get(schema, 'hideRequired', false);
    const title = get(schema, 'title', '');
    const tooltipMessage = get(schema, 'tooltipMessage', '');
    const id = _get(idSchema, '$id', '');
    const dateFormat = get(schema, 'dateFormat', '');

    const showRequired =
        formContext && (taintedFields.includes(id) || !pristine) && required && selected === null;

    const _onBlur = ({ target: { value } }) => {
        if (typeof onBlur === 'function') {
            onBlur(id, id);
        }
        const selectedDate = value;
        const updatedFormData = { ...formContext.getFormData() };
        const path = id.split('_').splice(1);
        const newValue = selectedDate ? _format(selectedDate, dateFormat) : '';
        _set(updatedFormData, path.join('.'), newValue);
        return formContext.updateFormData({ formData: updatedFormData });
    };

    return (
        <>
            {!isEmpty(title) && (
                <TitleStyled titleHeight={titleHeight}>
                    <FormGroupTitle
                        helpText={titleHelpText}
                        id={id}
                        hideRequired={hideRequired}
                        required={showRequired}
                        title={title}
                        showOptionalMark={!required}
                    />
                    {tooltipMessage && (
                        <ContentTooltipPreview
                            content={tooltipMessage}
                            icon={<InfoIcon />}
                            placement="top"
                        />
                    )}
                </TitleStyled>
            )}
            <StyledWrapper required={showRequired}>
                <ReactDatePicker
                    onBlur={_onBlur}
                    onChange={onChange}
                    onSelect={onSelect}
                    selected={selected}
                    placeholderText={placeholder}
                    key="__reactDatePicker"
                    required={required}
                    {...rest}
                />
                {showRequired && <DisplayRequiredWithIconField />}
            </StyledWrapper>
        </>
    );
}

DatePicker.propTypes = {
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    placeholder: PropTypes.string,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    required: PropTypes.bool,
    formContext: PropTypes.object,
    idSchema: PropTypes.object,
    uiSchema: PropTypes.object,
    schema: PropTypes.object,
};

DatePicker.defaultProps = {
    onBlur: () => {},
    onChange: () => {},
    onSelect: () => {},
    placeholder: '',
    selected: null,
    required: false,
    formContext: {
        taintedFields: [],
        pristine: true,
    },
    idSchema: {},
    uiSchema: {},
    schema: {},
};

export default DatePicker;
