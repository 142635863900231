import { createActions } from 'utils/actions';

/**
 * Redux actions for create questionnaire.
 *
 * @type {Object}
 */
export const fetchMyTickets = createActions('myTickets', 'LIST_MY_TICKETS', { asPromise: true });

export const fetchMyTicketsDetails = createActions('myTickets', 'FETCH_TICKET_DETAILS', {
    asPromise: true,
});

export const updateMyCaseDetails = createActions('myTickets', 'UPDATE_MY_CASE_DETAILS', {
    asPromise: true,
});

export const fetchMyTicketsTopics = createActions('myTickets', 'FETCH_TICKET_TOPICS', {
    asPromise: true,
});

export const fetchMyCaseTypes = createActions('myTickets', 'FETCH_USER_CASE_TYPES', {
    asPromise: true,
});

export const fetchMyCaseStatuses = createActions('myTickets', 'FETCH_USER_CASE_STATUSES', {
    asPromise: true,
});

export const fetchCustomerCaseCounts = createActions('myTickets', 'FETCH_CUSTOMER_CASE_COUNTS', {
    asPromise: true,
});

export const createTicket = createActions('myTickets', 'CREATE_TICKET', {
    asPromise: true,
});

export const fetchComments = createActions('myTickets', 'FETCH_COMMENTS', {
    asPromise: true,
});

export const createComment = createActions('myTickets', 'CREATE_COMMENT', {
    asPromise: true,
});

export const updateComment = createActions('myTickets', 'UPDATE_COMMENT', {
    asPromise: true,
});

export const deleteComment = createActions('myTickets', 'DELETE_COMMENT', {
    asPromise: true,
});

export const fetchUnidentifiedCase = createActions('myTickets', 'FETCH_UNIDENTIFIED_CASE', {
    asPromise: true,
});

export const fetchUnidentifiedCases = createActions('myTickets', 'FETCH_UNIDENTIFIED_CASES', {
    asPromise: true,
});

export const respondToUnidentifiedCase = createActions(
    'myTickets',
    'RESPOND_TO_UNIDENTIFIED_CASE',
    {
        asPromise: true,
    }
);

export const editUnidentifiedCase = createActions('myTickets', 'EDIT_UNIDENTIFIED_CASE', {
    asPromise: true,
});

export const cancelUnidentifiedCase = createActions('myTickets', 'CANCEL_UNIDENTIFIED_CASE', {
    asPromise: true,
});

export const cancelUnidentifiedCases = createActions('myTickets', 'CANCEL_UNIDENTIFIED_CASES', {
    asPromise: true,
});

export const fetchUnidentifiedCaseTypes = createActions(
    'myTickets',
    'FETCH_UNIDENTIFIED_CASE_TYPES',
    {
        asPromise: true,
    }
);

export const fetchAttachmentUrl = createActions('myTickets', 'FETCH_ATTACHMENT_URL', {
    asPromise: true,
});

export const fetchVpa = createActions('myTickets', 'FETCH_VPA', { asPromise: true });

export const fetchCaseSurvey = createActions('myTickets', 'FETCH_CASE_SURVEY', {
    asPromise: true,
});

export const createCaseSurvey = createActions('myTickets', 'CREATE_CASE_SURVEY', {
    asPromise: true,
});

export const fetchQuickSightUrl = createActions('myTickets', 'FETCH_QUICKSIGHT_DASHBOARD', {
    asPromise: true,
});

export const fetchCaseAssignments = createActions('myTickets', 'FETCH_CASE_ASSIGNMENTS', {
    asPromise: true,
});

export const transferCase = createActions('myTickets', 'TRANSFER_CASE', {
    asPromise: true,
});

export const transferCases = createActions('myTickets', 'TRANSFER_CASES', {
    asPromise: true,
});

export const fetchPreppedAnswers = createActions('myTickets', 'FETCH_PREPPED_ANSWERS', {
    asPromise: true,
});

export const fetchMultiplePreppedAnswers = createActions(
    'myTickets',
    'FETCH_MULTIPLE_PREPPED_ANSWERS',
    {
        asPromise: true,
    }
);

export const favoritePreppedAnswer = createActions('myTickets', 'FAVORITE_PREPPED_ANSWER', {
    asPromise: true,
});

export const unFavoritePreppedAnswer = createActions('myTickets', 'UNFAVORITE_PREPPED_ANSWER', {
    asPromise: true,
});

export const deletePreppedAnswer = createActions('myTickets', 'DELETE_PREPPED_ANSWER', {
    asPromise: true,
});

export const fetchPreppedAnswerDetails = createActions(
    'myTickets',
    'FETCH_PREPPED_ANSWER_DETAILS',
    {
        asPromise: true,
    }
);

export const fetchAllSuggestedPAUpdates = createActions(
    'myTickets',
    'FETCH_ALL_SUGGESTED_PA_EDITS',
    {
        asPromise: true,
    }
);

export const fetchSuggestedPAUpdates = createActions('myTickets', 'FETCH_SUGGESTED_PA_EDITS', {
    asPromise: true,
});

export const suggestPAUpdate = createActions('myTickets', 'SUGGEST_PA_EDIT', {
    asPromise: true,
});

export const approveSuggestedPAUpdate = createActions('myTickets', 'APPROVE_SUGGESTED_PA_EDIT', {
    asPromise: true,
});

export const declineSuggestedPAUpdate = createActions('myTickets', 'DECLINE_SUGGESTED_PA_EDIT', {
    asPromise: true,
});

export const fetchAllCaseCounts = createActions('myTickets', 'FETCH_ALL_CASE_COUNTS', {
    asPromise: true,
});

export const fetchMessages = createActions('myTickets', 'FETCH_MESSAGES', { asPromise: true });

export const createMessage = createActions('myTickets', 'CREATE_MESSAGES', { asPromise: true });

export const getMessageTypes = createActions('myTickets', 'GET_MESSAGE_TYPES', { asPromise: true });

export const nominatePreppedAnswer = createActions('myTickets', 'NOMINATE_PREPPED_ANSWER', {
    asPromise: true,
});

export const downgradeVpa = createActions('myTickets', 'DOWNGRADE_VPA', { asPromise: true });

export const completeVpa = createActions('myTickets', 'COMPLETE_VPA', { asPromise: true });

export const declineVpa = createActions('myTickets', 'DECLINE_VPA', { asPromise: true });

export const updatePreppedAnswer = createActions('myTickets', 'UPDATE_PREPPED_ANSWER', {
    asPromise: true,
});

export const fetchTicketCounts = createActions('myTickets', 'FETCH_TICKET_COUNTS', {
    asPromise: true,
});

export const fetchTickets = createActions('myTickets', 'FETCH_TICKETS', { asPromise: true });

export const createCaseConversationMessage = createActions(
    'myTickets',
    'CREATE_CASE_CONVERSATION_MESSAGE',
    {
        asPromise: true,
    }
);

export const fetchCaseConversation = createActions('myTickets', 'FETCH_CASE_CONVERSATION', {
    asPromise: true,
});

export const createMyCaseConversationMessage = createActions(
    'myTickets',
    'CREATE_MY_CASE_CONVERSATION_MESSAGE',
    {
        asPromise: true,
    }
);

export const fetchMyCaseConversation = createActions('myTickets', 'FETCH_MY_CASE_CONVERSATION', {
    asPromise: true,
});

export const fetchCaseDetails = createActions('myTickets', 'FETCH_CASE_DETAILS', {
    asPromise: true,
});

export const updateCaseDetails = createActions('myTickets', 'UPDATE_CASE_DETAILS', {
    asPromise: true,
});

export const removeMyCaseAttachment = createActions('myTickets', 'REMOVE_MY_CASE_ATTACHMENT', {
    asPromise: true,
});

export const removeCaseAttachment = createActions('myTickets', 'REMOVE_CASE_ATTACHMENT', {
    asPromise: true,
});

export const setCaseMetricsCollapsed = createActions('myTickets', 'SET_CASE_METRICS_COLLAPSED');

export const fetchContentLinks = createActions('myTickets', 'FETCH_CONTENT_LINKS', {
    asPromise: true,
});

export const createPreppedAnswer = createActions('myTickets', 'CREATE_PREPPED_ANSWER', {
    asPromise: true,
});

export const linkPreppedAnswer = createActions('myTickets', 'LINK_PREPPED_ANSWER', {
    asPromise: true,
});

export const fetchCaseTopics = createActions('myTickets', 'FETCH_CASE_TOPICS', {
    asPromise: true,
});

export const createResources = createActions('myTickets', 'CREATE_RESOURCES', {
    asPromise: true,
});

export const updateResources = createActions('myTickets', 'UPDATE_RESOURCES', {
    asPromise: true,
});

export const deleteResources = createActions('myTickets', 'DELETE_RESOURCES', {
    asPromise: true,
});

export const unlinkPreppedAnswer = createActions('myTickets', 'UNLINK_PREPPED_ANSWER', {
    asPromise: true,
});

export const fetchCaseSources = createActions('myTickets', 'FETCH_CASE_SOURCES', {
    asPromise: true,
});

export const fetchCaseTasks = createActions('myTickets', 'FETCH_CASE_TASKS', {
    asPromise: true,
});

export const createCaseTask = createActions('myTickets', 'CREATE_CASE_TASK', {
    asPromise: true,
});

export const updateCaseTask = createActions('myTickets', 'UPDATE_CASE_TASK', {
    asPromise: true,
});

export const assignCaseTask = createActions('myTickets', 'ASSIGN_CASE_TASK', {
    asPromise: true,
});

export const activateCaseTask = createActions('myTickets', 'ACTIVATE_CASE_TASK', {
    asPromise: true,
});

export const acceptCaseTask = createActions('myTickets', 'ACCEPT_CASE_TASK', {
    asPromise: true,
});

export const completeCaseTask = createActions('myTickets', 'COMPLETE_CASE_TASK', {
    asPromise: true,
});

export const deleteCaseTask = createActions('myTickets', 'DELETE_CASE_TASK', {
    asPromise: true,
});

export const fetchCaseTaskTypes = createActions('myTickets', 'FETCH_CASE_TASK_TYPES', {
    asPromise: true,
});

export const fetchCaseResourceTypes = createActions('myTickets', 'FETCH_CASE_RESOURCE_TYPES', {
    asPromise: true,
});

export const createNewCase = createActions('myTickets', 'CREATE_NEW_CASE', {
    asPromise: true,
});

export const fetchCaseHistory = createActions('myTickets', 'FETCH_CASE_HISTORY', {
    asPromise: true,
});

export const reopenCase = createActions('myTickets', 'REOPEN_CASE', {
    asPromise: true,
});

export const cancelCase = createActions('myTickets', 'CANCEL_CASE', {
    asPromise: true,
});

export const createChatMessageReview = createActions('myTickets', 'CREATE_CHAT_MESSAGE_REVIEW', {
    asPromise: true,
});

export const fetchChatMessageReviews = createActions('myTickets', 'FETCH_CHAT_MESSAGE_REVIEWS', {
    asPromise: true,
});

export const updateChatMessageReview = createActions('myTickets', 'UPDATE_CHAT_MESSAGE_REVIEW', {
    asPromise: true,
});
