import get from 'lodash/get';

import Acl from 'modules/acl';
import { isGenesisInternalUser } from 'utils/user';

import { configContainsSku } from './configurations';

import { CLIENT_SOLUTION_REPORT, MAP_SKU_KEY } from '../constants/reports';

/**
 * Get subnavigation links.
 *
 * @method getFormattedLinks
 * @param  {Object} items
 * @return {Object}
 */
export const getFormattedLinks = items => {
    const links = items.map(({ title, to, permission }) => {
        if (Acl.check(permission)) {
            return {
                label: title,
                jumpTo: to,
            };
        }
        return null;
    });
    return links.filter(el => el != null);
};

export const isClientSolutionReportDisabled = (userCompany, userRole) => {
    const hasLicense = Boolean(userCompany?.licenses?.length > 0);
    const isLoggedInIntuitPartner =
        userCompany?.displayName === CLIENT_SOLUTION_REPORT.INTUIT_PARTNER_NAME;
    const isLoggedInUserRoleInternal = isGenesisInternalUser(userRole);

    if (isLoggedInUserRoleInternal) {
        return false;
    }

    return !hasLicense || isLoggedInIntuitPartner;
};

export const isPartnerValueReportDisabled = (userCompany, userRole) => {
    const companySkus = get(userCompany, 'skus', []);
    const hasMapSku = configContainsSku({ skus: companySkus }, MAP_SKU_KEY.SKU_KEY);

    if (isGenesisInternalUser(userRole) || hasMapSku) {
        return false;
    }

    return true;
};
