import axios from 'axios';
import get from 'lodash/get';
import queryString from 'query-string';
import _isEmpty from 'lodash/isEmpty';

import {
    API_ORIGIN,
    API_TOOLS_ORIGIN,
    TICKETS_ORIGIN,
    STATIC_JSON_ORIGIN,
    SURVEYS_ORIGIN,
    ZEND_ORIGIN,
    MNL_AUTH_URL,
    AI_ASSISTANT_URL,
    MOCK_ACA_API_URL,
} from 'constants/api';
import { SUCCESS, ERROR } from 'constants/actions';
import { MODALS } from 'constants/modals';
import MESSAGES from 'constants/messages';
import { getActionWithSuffix } from 'utils/actions';
import { actions as authActions, selectors as authSelectors } from 'modules/auth';
import { actions as uiActions } from 'modules/ui';

/**
 * An oAuthToken Interceptor for all outgoing 'axios' requests
 *
 * @method oAuthTokenRequestInterceptor
 * @param  {Function}                     getState Get a snapshot of the current state
 * @param  {Object}                       config   The axios config
 * @return {Object}                                The updated axios config
 */
export const oAuthTokenRequestInterceptor = ({ getState }, config) => {
    // We'll make a copy of the current config,
    // we don't want anything updated to the axios request via reference.
    const updatedConfig = { ...config };
    const ignoreAuthorization = config.ignoreAuthorization || false;
    // There are several instances where we have to change the payload
    // for the APIs based on the old Zend API's and the newer Java REST APIs.
    // Determine if we're calling a `zend` endpoint vs regular `japi` endpoint.
    const { type } = config;
    const bearer = config.noBearer ? '' : 'Bearer ';
    const stateSnapshot = getState();
    const oAuthToken = config.oAuthToken || authSelectors.getAccessToken(stateSnapshot);

    if (updatedConfig.forceRefreshToken) {
        const oAuthRefreshToken = authSelectors.getRefreshToken(stateSnapshot);
        updatedConfig.url = `${updatedConfig.url}?refreshToken=${oAuthRefreshToken}`;
    }

    // NOTE: For now we're hard-coding the current API_ORIGIN,
    // but later it will come from the `bootstrap.json` cycle.
    let baseURL;
    switch (type) {
        case 'zend':
            baseURL = ZEND_ORIGIN;
            break;
        case 'apiTools':
            baseURL = API_TOOLS_ORIGIN;
            break;
        case 'tickets':
            baseURL = TICKETS_ORIGIN;
            break;
        case 'staticJson':
            baseURL = STATIC_JSON_ORIGIN;
            break;
        case 'auth':
            baseURL = MNL_AUTH_URL;
            break;
        case 'surveys':
            baseURL = SURVEYS_ORIGIN;
            break;
        case 'aiAssistant':
            baseURL = AI_ASSISTANT_URL;
            break;
        case 'mockACA': // Todo: Should be removed once JAPI is ready.
            baseURL = MOCK_ACA_API_URL;
            break;
        default:
            baseURL = API_ORIGIN;
    }
    // Update the `baseURL` if none is present in the current config.
    if (!updatedConfig.baseURL) {
        updatedConfig.baseURL = baseURL;
    }
    // If there is an `oAuthToken` present, then let's update the
    // Authorization for the current request.
    if (!ignoreAuthorization && oAuthToken && !updatedConfig.headers.common.Authorization) {
        updatedConfig.headers.common.Authorization =
            type === 'zend' ? `${oAuthToken}` : bearer + oAuthToken;
    }
    // Finally, return the updated config.
    return updatedConfig;
};

/**
 * onFulfilled callback for axios response interceptor
 *
 * @param   {Object}    response
 * @return  {Object}
 */
export const onResponseFulfilled = response => response;

/**
 * onRejected callback for axios response interceptor
 *
 * @param   {Object}    store
 * @param   {Object}    history
 * @param   {Object}    error
 * @return  {Object|Promise}
 */
export const onResponseRejected = (store, history, error) => {
    const status = get(error, 'response.status', null);
    const initialRequestConfig = get(error, 'config');
    const preventRefreshToken = get(initialRequestConfig, 'preventRefreshToken', false);

    if (status === 401 && !initialRequestConfig.retry && !preventRefreshToken) {
        initialRequestConfig.retry = true;
        delete initialRequestConfig.headers.Authorization;

        return refreshToken(store)
            .then(oAuthToken => {
                return axios({ ...initialRequestConfig, oAuthToken });
            })
            .catch(() => {
                const stateSnapshot = store.getState();
                const isUserLoggedIn = authSelectors.isUserLoggedIn(stateSnapshot);

                if (isUserLoggedIn) {
                    const { dispatch } = store;
                    return dispatch(
                        uiActions.openModal(MODALS.CONFIRM, {
                            title: MESSAGES.MODAL.TOKEN_EXPIRED.TITLE,
                            description: MESSAGES.MODAL.TOKEN_EXPIRED.DESCRIPTION,
                            actions: [
                                {
                                    text: MESSAGES.MODAL.TOKEN_EXPIRED.ACTIONS.LOGIN,
                                    color: 'primary',
                                    onClick() {
                                        uiActions.closeModal(MODALS.CONFIRM);
                                        const { from, partnerLoginUrl } = queryString.parse(
                                            !_isEmpty(history.location) && history.location.search
                                        );
                                        if (partnerLoginUrl) {
                                            history.push(
                                                `/auth/logout?partnerLoginUrl=${partnerLoginUrl}`
                                            );
                                        } else if (from) {
                                            history.push(`/auth/logout?from=${from}`);
                                        } else {
                                            history.push('/auth/logout');
                                        }
                                    },
                                },
                            ],
                        })
                    );
                } else {
                    return Promise.reject(error);
                }
            });
    } else {
        return Promise.reject(error);
    }
};

/**
 * The `onSuccess` handler for all successful axios redux action requests.
 *
 * @method onSuccess
 * @param  {Object}  store        The redux store options
 * @param  {Object}  axiosOptions The axios middleware options
 * @return {Object}               The next action in queue
 */
const onSuccess = (store, axiosOptions) => {
    // Extract the needed params from the store.
    const { action, next, response } = store;
    // Build the `nextAction` to be dispatched.
    const nextAction = {
        payload: response,
        type: getActionWithSuffix('success')(action.type),
        meta: {
            previousAction: action,
        },
    };
    // Remove the request object from the error,
    // this stops axios from trying to make another ajax request.
    // TODO: Look more into this error.
    if (nextAction.payload.request && nextAction.payload.request instanceof XMLHttpRequest) {
        delete nextAction.payload.request;
    }
    // Dispatch the `nextAction`.
    next(nextAction);
    // Return the `nextAction`.
    return nextAction;
};

/**
 * The `onError` handler for all successful axios redux action requests.
 *
 * @method onError
 * @param  {Object}  store        The redux store options
 * @param  {Object}  axiosOptions The axios middleware options
 * @return {Object}               The next action in queue
 */
const onError = (store, axiosOptions) => {
    // Extract the needed params from the store.
    const { action, next, error } = store;
    // Build the `nextAction` to be dispatched.
    // This is an FSA error action.
    const nextAction = {
        error: true,
        payload: error,
        type: getActionWithSuffix('error')(action.type),
        meta: {
            previousAction: action,
        },
    };
    // Remove the request object from the error,
    // this stops axios from trying to make another ajax request.
    // TODO: Look more into this error.
    if (nextAction.payload.request && nextAction.payload.request instanceof XMLHttpRequest) {
        delete nextAction.payload.request;
    }
    // Dispatch the `nextAction`.
    next(nextAction);
    // Return the `nextAction`.
    return nextAction;
};

/**
 * Refresh Token
 *
 * @param  {Object}    store
 * @return {Promise}
 */
const refreshToken = async store => {
    try {
        const { dispatch } = store;

        return await dispatch(authActions.refreshToken());
    } catch (e) {
        return Promise.reject(e);
    }
};

/**
 * Define the options for the Axios Redux Middleware
 *
 * @type {Object}
 */
export const axiosMiddlewareOptions = {
    onError,
    onSuccess,
    successSuffix: `_${SUCCESS}`,
    errorSuffix: `_${ERROR}`,
    // returnRejectedPromiseOnError: true,
    interceptors: {
        request: [oAuthTokenRequestInterceptor],
        response: [],
    },
};
