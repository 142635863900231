import axios from 'axios';

/**
 * API call to fetch aca questionnaire information
 *
 * @method fetchQuestionnaireInfo
 * @return {Promise}
 */
export const fetchQuestionnaireInfoAPI = () => {
    return axios({
        method: 'get',
        url: `/v2/questionnaires?name=aca`,
        type: 'mockACA',
    });
};

/**
 * API call to fetch aca questionnaire status
 *
 * @method fetchQuestionnaireStatus
 * @param   {number}    id
 * @param   {number}    companyId
 * @return {Promise}
 */
export const fetchQuestionnaireStatusAPI = ({ id, companyId }) => {
    return axios({
        method: 'get',
        url: `/v2/questionnaires/${id}/status?companyId=${companyId}`,
        type: 'mockACA',
    });
};

/**
 * API call to set aca questionnaire status
 *
 * @method setQuestionnaireStatus
 * @param   {number}    id
 * @param   {Object}    data
 * @return {Promise}
 */
export const setQuestionnaireStatusAPI = ({ questionnaireId, data }) => {
    return axios({
        method: 'POST',
        url: `/v2/questionnaires/${questionnaireId}/status`,
        type: 'mockACA',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
/**
 * API call to fetch all questions
 *
 * @method fetchQuestionsAPI
 * @param   {number}    id
 * @return {Promise}
 */
export const fetchQuestionsAPI = ({ questionnaireId }) => {
    return axios({
        method: 'get',
        url: `/v2/questionnaire/${questionnaireId}/questions`,
        type: 'mockACA',
    });
};

/**
 * API call to fetch list of all answers
 *
 * @method fetchAnswerListAPI
 * @param   {number}    questionnaireId
 * @param   {number}    questionId
 * @param   {number}    id
 * @return {Promise}
 */
export const fetchAnswerListAPI = ({ questionnaireId, questionId, id }) => {
    return axios({
        method: 'get',
        url: `/v2/questionnaire/${questionnaireId}/question/${questionId}/answers?companyId=${id}
`,
        type: 'mockACA',
    });
};

/**
 * API call to create answer for a question
 *
 * @method createAnswerAPI
 * @param   {number}    questionnaireId
 * @param   {number}    questionId
 * @param   {Object}    data
 * @return {Promise}
 */
export const createAnswerAPI = ({ questionnaireId, questionId, data }) => {
    return axios({
        method: 'post',
        url: `/v2/questionnaire/${questionnaireId}/question/${questionId}/answer`,
        type: 'mockACA',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API call to update answer for a question
 *
 * @method updateAnswerAPI
 * @param   {number}    questionnaireId
 * @param   {number}    questionId
 * @param   {number}    id
 * @param   {Object}    data
 * @return {Promise}
 */
export const updateAnswerAPI = ({ questionnaireId, questionId, data, id }) => {
    return axios({
        method: 'put',
        url: `/v2/questionnaire/${questionnaireId}/question/${questionId}/answer/${id}`,
        type: 'mockACA',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 * API call to delete answer for a question
 *
 * @method deleteAnswerAPI
 * @param   {number}    questionnaireId
 * @param   {number}    questionId
 * @param   {number}    answerId
 * @return {Promise}
 */
export const deleteAnswerAPI = ({ questionnaireId, questionId, answerId }) => {
    return axios({
        method: 'delete',
        url: `/v2/questionnaire/${questionnaireId}/question/${questionId}/answer/${answerId}`,
        type: 'mockACA',
    });
};
